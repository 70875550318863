function initMap() {
  const maps = document.querySelectorAll('.map');

  Array.from(maps).forEach(cnt => {
    const coords = { "lat": Number(cnt.dataset.lat), "lng": Number(cnt.dataset.long) };
    
    const map = new google.maps.Map(cnt, {
      zoom: 13,
      center: coords,
    });

    new google.maps.Marker({
      position: coords,
      map,
    });
  })
}

window.initMap = initMap;